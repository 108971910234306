var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"persistent":"","width":"800"}},[_c('v-card',{attrs:{"color":"#fafafa"}},[_c('v-toolbar',{attrs:{"color":"#1C3144"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('update:value', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Copy - "+_vm._s(_vm.selectedAd.adName))]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"return-value":_vm.adDate,"close-on-content-click":false,"nudge-top":"25","nudge-left":25,"transition":"scale-y-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-6",attrs:{"outlined":"","dense":"","background-color":"white","light":"","value":_vm.formattedDate,"label":"Ad Date","prepend-inner-icon":"mdi-calendar"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"show-current":false,"no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.adDate),callback:function ($$v) {_vm.adDate=$$v},expression:"adDate"}})],1)],1)],1),_c('v-data-table',{staticClass:"table",attrs:{"items-per-page":_vm.pageSize,"height":_vm.tableSize,"headers":_vm.headers,"items":_vm.destinationAds,"fixed-header":"","dense":"","hide-default-footer":"","show-select":"","item-key":"id"},on:{"update:items":function($event){_vm.destinationAds=$event}},scopedSlots:_vm._u([{key:"item.features",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.features.length))])]}},{key:"item.readyForUpload",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.readyForUpload ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.readyForUpload ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close')+" ")])]}},{key:"footer",fn:function(){return [_c('v-toolbar',{staticClass:"tableFooter",attrs:{"flat":"","color":"#fafafa"}},[_c('v-spacer'),_c('v-btn',{staticClass:"white--text ml-2",attrs:{"disabled":_vm.adDate && !_vm.selected.length,"height":"40","color":"#F9A825"},on:{"click":_vm.copy}},[_vm._v(" Copy"),_c('v-icon',{staticClass:"ml-1",attrs:{"dense":""}},[_vm._v("mdi-content-duplicate")])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }